.section-ruko {
  margin: 65px 0;
  padding: 65px 0;
  background-color: #f1f1e6;
  display: flex;
  flex-direction: column;
  text-align: center;
  text-transform: uppercase;
  gap: 40px;
  h1,
  h2,
  h3,
  p {
    margin: 0;
  }
  .section-title {
    color: #0e4274;
    font-family: "Amiko", Sans-serif;
    font-size: 50px;
    font-weight: 600;
  }
  .section-subtitle {
    color: #0e4274;
    font-size: 36px;
    @media (max-width: 768px) {
      text-align: start;
    }
  }
  .ruko-container-1 {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .slider-1 {
      .slick-slider {
        width: 90%;
        z-index: 1;
        left: 5%;
        @media (max-width: 768px) {
          width: 100%;
          left: 0;
        }
      }
      .slick-dots {
        bottom: 8px;
      }

      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slider-container-1 {
        z-index: 1;
        border-radius: 10px;
        .image-slider-1 {
          width: 100%;
          object-fit: cover;

          @media (max-width: 768px) {
            height: auto;
          }
        }
      }
    }
    .container-card-1 {
      display: flex;
      gap: 40px 20px;
      padding: 0 20px;
      flex-wrap: wrap;
      justify-content: center;
      @media (max-width: 768px) {
        padding: 0 6px;
        gap: 20px;
      }
      .card {
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0;
        width: calc((100% / 3) - 20px);
        text-transform: uppercase;
        border-radius: 10px;
        @media (max-width: 768px) {
          width: 100%;
        }
        .kartu-gambar {
          width: 80%;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
        }
        .slick-slider {
          width: 100%;
          .slick-next::before,
          .slick-prev::before {
            font-family: "slick";
            font-size: 25px;
          }
          .slick-track {
            display: flex;
            justify-content: center;
            align-items: center;
            align-self: center;
          }

          .slick-dots {
            position: absolute; /* Make dots absolute for positioning within slides */
            bottom: 10px; /* Adjust vertical positioning */
            left: 50%; /* Center dots horizontally */
            transform: translateX(-50%); /* Center dots precisely */
            z-index: 1; /* Ensure dots are above image content */
          }
          .slick-next {
            right: 25px;
          }
          .slick-prev {
            z-index: 1;
            left: 25px;
          }
          .slick-dots {
            li {
              margin: 0;
            }
          }
        }

        .card-description {
          margin-top: -5px;
          color: #0e4274;
          background-color: #fffefb;
          border-end-start-radius: 10px;
          border-end-end-radius: 10px;
          padding: 0px 0px 20px 0px;
          .price {
            font-size: 60px;
            font-weight: 600;
          }
          .highlight {
            color: #3b3730;
            font-size: 27px;
            font-weight: 700;
          }
          .gridspek {
            padding: 10px 0;
            padding-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            box-shadow: 0px 0.5px 5px 0px #3b3730;

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-left: 5px;
              padding-right: 25px;
              color: #3b3730;
            }
          }

          .containerwhatsapp {
            display: flex;
            justify-content: center;
            padding: 25px 25px 0px 25px;
            .whatsapp {
              display: inline-block;
              width: 100%;
              cursor: pointer;
              border: none;
              padding: 7px 0;
              border-radius: 5px;
              background-color: #0e4274; /* WhatsApp green */
              color: #fffefb;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }

  .ruko-container-2 {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .slider-2 {
      .slick-slider {
        width: 90%;
        z-index: 1;
        left: 5%;
        @media (max-width: 768px) {
          width: 100%;
          left: 0;
        }
      }
      .slick-dots {
        bottom: 8px;
      }

      .slick-next {
        right: 25px;
      }
      .slick-prev {
        z-index: 1;
        left: 25px;
      }
      .slider-container-1 {
        z-index: 1;
        border-radius: 10px;
        .image-slider-1 {
          width: 100%;
          object-fit: cover;

          @media (max-width: 768px) {
            height: auto;
          }
        }
      }
    }
    .ruko-description {
      display: flex;
      text-align: start;
      font-size: 18px;
      gap: 20px;
      justify-content: center;
      align-self: center;
      text-transform: none;
      width: 80%;
      padding: 0 20px;
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 0;
      }
      .point-container {
        width: calc(100% / 2 - 20px);
        @media (max-width: 768px) {
          width: 100%;
        }
      }
      .ruko-point {
        margin-bottom: 5px;
        color: #3b3730;
        font-weight: 600;
      }
    }
    .container-card-2 {
      display: flex;
      gap: 40px 20px;
      padding: 0 20px;
      flex-wrap: wrap;
      justify-content: center;
      @media (max-width: 768px) {
        padding: 0 6px;
        gap: 20px;
      }
      .card {
        display: flex;
        flex-direction: column;
        box-shadow: 0 0 10px 0;
        width: calc((100% / 3) - 20px);
        text-transform: uppercase;
        border-radius: 10px;
        @media (max-width: 768px) {
          width: 100%;
        }

        .card-description {
          margin-top: -5px;
          color: #0e4274;
          background-color: #fffefb;
          border-radius: 10px;
          padding: 0px 0px 20px 0px;
          .price {
            font-size: 60px;
            font-weight: 600;
          }
          .highlight {
            color: #3b3730;
            font-size: 27px;
            font-weight: 700;
          }
          .gridspek {
            border-start-start-radius: 10px;
            border-start-end-radius: 10px;
            padding: 10px 0;
            padding-left: 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 10px;
            box-shadow: 0px 0.5px 5px 0px #3b3730;

            span {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-left: 5px;
              padding-right: 25px;
              color: #3b3730;
            }
          }

          .containerwhatsapp {
            display: flex;
            justify-content: center;
            padding: 25px 25px 0px 25px;
            .whatsapp {
              display: inline-block;
              width: 100%;
              cursor: pointer;
              border: none;
              padding: 7px 0;
              border-radius: 5px;
              background-color: #0e4274; /* WhatsApp green */
              color: #fffefb;
              font-size: 16px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
