.lokasi-section {
  margin: 65px 0;
  display: flex;
  flex-direction: column;
  .lokasi-title {
    text-align: center;
    font-size: 32px;
  }
  .lokasi-container {
    .lokasi-description {
      width: 80%;
      margin: auto;
      font-size: 18px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
    .image-container {
      width: 80%;
      margin: 0 auto;
      @media (max-width: 768px) {
        width: 100%;
      }
      .lokasi-image {
        width: 100%;
        height: 500px;
        object-fit: cover;
      }
    }
  }
}
