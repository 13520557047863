.rumahbaru {
  display: flex;
  flex-direction: column;
  text-align: center;
  h1,
  h2,
  h3,
  p {
    margin: 0;
  }
  width: 80%;
  margin: 30px auto;
  background-color: #0e4274;
  @media (max-width: 768px) {
    width: 90%;
  }
  .section-title {
    color: white;
    font-family: "Amiko", Sans-serif;
    font-size: 50px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  .container-card {
    display: flex;
    gap: 40px 20px;

    flex-wrap: wrap;
    justify-content: center;
    @media (max-width: 768px) {
      gap: 20px;
    }
    .card {
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 10px 0;
      width: calc((100% / 3) - 14px);
      text-transform: uppercase;
      border-radius: 10px;
      @media (max-width: 768px) {
        width: 100%;
      }
      .kartu-gambar {
        width: 80%;
        height: 550px;
        object-fit: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        @media (max-width: 768px) {
          height: 400px;
        }
      }
      .slick-slider {
        width: 100%;
        .slick-next::before,
        .slick-prev::before {
          font-family: "slick";
          font-size: 25px;
        }
        .slick-track {
          display: flex;
          justify-content: center;
          align-items: center;
          align-self: center;
        }

        .slick-dots {
          position: absolute; /* Make dots absolute for positioning within slides */
          bottom: 10px; /* Adjust vertical positioning */
          left: 50%; /* Center dots horizontally */
          transform: translateX(-50%); /* Center dots precisely */
          z-index: 1; /* Ensure dots are above image content */
        }
        .slick-next {
          right: 25px;
        }
        .slick-prev {
          z-index: 1;
          left: 25px;
        }
        .slick-dots {
          li {
            margin: 0;
          }
        }
      }

      .card-description {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: -5px;
        color: #fffefb;
        background-color: #3b3730;
        border-end-start-radius: 10px;
        border-end-end-radius: 10px;
        padding: 20px 0px;
        flex-grow: 1;
        .price {
          font-size: 60px;
          font-weight: 600;
        }
        .highlight {
          color: #3b3730;
          font-size: 27px;
          font-weight: 700;
          background-color: #c09665;
          padding: 5px 0 9px 0;
        }
        .gridspek {
          padding: 10px 0 0 0;
          padding-left: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 5px;
            padding-right: 25px;
            @media (max-width: 768px) {
              padding-left: 0px;
              padding-right: 10px;
            }
          }
        }
        .containerwhatsapp {
          display: flex;
          justify-content: center;
          padding: 25px 25px 0px 25px;
          .whatsapp {
            display: inline-block;
            width: 100%;
            cursor: pointer;
            border: none;
            padding: 7px 0;
            border-radius: 5px;
            background-color: #fffefb; /* WhatsApp green */
            color: #3b3730;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }
    }
  }
}
