.promo-section {
  display: flex;
  justify-content: center;
  gap: 20px;
  align-items: center;
  padding: 0 2.5rem;
  background-color: #0e4274;
  padding: 40px;
  flex-wrap: wrap;
  margin: 65px 0;

  @media (max-width: 768px) {
    padding: 1rem;
  }

  .promo-slider {
    width: 50%;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      width: 100%;
    }

    .flyer-image {
      width: 80%;
      box-shadow: 0 0 10px 0;
      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .promo-content {
    width: 35%;
    padding: 0 20px;
    @media (max-width: 768px) {
      width: 100%;
    }

    h3 {
      margin: 0;
      font-size: 32px;
      margin-bottom: 20px;
      color: white;
    }
    .promo-description {
      .promo-point {
        display: flex;
        margin-bottom: 12px;
        span {
          font-size: 18px;
          font-weight: 600;
        }
      }
    }
    .button-wa-promo {
      padding-top: 15px;
      padding-bottom: 10px;
      .whatsapp {
        width: 60%;
        padding: 7px 9px;
        display: inline-block;
        cursor: pointer;
        border-radius: 9px;
        border: solid;
        border-width: 1px;
        background-color: #f1f1e6;
        /*WhatsAppgreen*/
        color: #3b3730;
        font-size: 16px;
        transition-duration: 0.3s;
        @media (max-width: 768px) {
          width: 100%;
        }
        &:hover {
          background-color: #3b3730;
          color: white;
          padding: 15px 0;
          transition-duration: 0.3s;
        }
      }
      span {
        margin-left: 6px;
      }
    }
  }
}
