.surounding {
  width: 100%;
  .surrounding-title {
    text-align: center;
    color: #0e4274;
    font-family: "Amiko", Sans-serif;
    font-size: 50px;
    font-weight: 600;
  }

  .slick-slider {
    width: 95%;
    z-index: 1;
    left: 2.5%;
    @media (max-width: 768px) {
      width: 100%;
      left: 0;
    }
  }
  .slick-dots {
    bottom: 8px;
  }

  .slick-next {
    right: 25px;
  }
  .slick-prev {
    z-index: 1;
    left: 25px;
  }
  .slider-container {
    z-index: 1;
    border-radius: 10px;
    .image {
      width: 100%;

      object-fit: cover;

      @media (max-width: 768px) {
        height: auto;
      }
    }
  }
}
